<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Add New Template</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

        <!-- Related Projects -->
        <b-form-group label="Related Project" label-for="project-name">
          <b-form-select
            v-model="templateData.project"
            :options="projectsOptions"
            required
            value-field="id"
            text-field="name_uuid"
            place-holder="d"
          ></b-form-select>
        </b-form-group>

        <!-- Type Market -->
        <b-form-radio-group
          v-model="selectedRadioTypeMarket"
          :options="optionsRadioTypeMarket"
          class="mb-1"
          value-field="item"
          text-field="name"
          disabled-field="notEnabled"
        ></b-form-radio-group>
        <b-form-group
          label="Chose any of existing Type Markets" 
          label-for="project-name"
          v-if="selectedRadioTypeMarket === 'existing'"
        >
          <b-form-select
            v-model="templateData.type_market"
            :options="typeMarketOptions"
            required
            value-field="value"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Or write new Type Market"
          label-for="project-name"
          v-if="selectedRadioTypeMarket === 'new'"
        >
          <b-form-input
            v-model="templateData.type_market"
            placeholder="Write new Type Market"
            type="text" />
        </b-form-group>

        <!-- Comment -->
        <b-form-group label="Comment" label-for="comment">
          <b-form-textarea
            id="textarea"
            v-model="templateData.comment"
            placeholder="Comment"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :class="{ submiting: submiting.status }"
              type="submit"
            >
              Add
              <b-spinner
                v-if="submiting.status"
                small
                class="ml-1"
                label="Small Spinner"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { useToast } from "vue-toastification/composition";
import { BSpinner } from "bootstrap-vue";


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      optionsRadioTypeMarket: ['existing', 'new'],
      selectedRadioTypeMarket: "existing"
    };
  },
  computed: {
    projectsOptions() {
      let internal_projects = []

      if (this.$store.state.Projects.projects) {
        internal_projects = this.$store.state.Projects.projects.filter((project) =>
        project.project_type === 'internal');
      }

      for (let i = 0; i < internal_projects.length; i++) {
        internal_projects[i].name_uuid = internal_projects[i].name + ' (' + internal_projects[i].uuid + ')'
      } // Create name_uuid option to use in b-form-select

      internal_projects.push({
        id: null,
        name_uuid: 'Please select a Related Project',
        disabled: true
      })

      return internal_projects.reverse()
    },
    typeMarketOptions() {
      let typeMarkets = this.$store.state.Templates.typeMarkets
      typeMarkets.unshift({
        value: null,
        text: 'Please select a Type Market',
        disabled: true
      })
      return typeMarkets
    },
  },
  setup(props, { emit }) {
    const submiting = { status: false };
    const blankTemplateData = {
      project: null,
      comment: null,
      type_market: null,
    };

    const templateData = ref(JSON.parse(JSON.stringify(blankTemplateData)));
    const resetTemplateData = () => {
      templateData.value = JSON.parse(JSON.stringify(blankTemplateData));
    };

    const toast = useToast();

    const onSubmit = () => {
      submiting.status = true;

      const req = {
        project: templateData.value.project,
        comment: templateData.value.comment,
        type_market: templateData.value.type_market
      };
      store
        .dispatch("Template/setNewTemplate", req)
        .then(() => {
          store.dispatch("Templates/GET_ALL_TEMPLATES")
          emit("refetch-data");
          emit("update:is-add-new-user-sidebar-active", false);
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "You have successfully created new Template",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          submiting.status = false;
        })
        .catch((err) => {
          submiting.status = false;
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetTemplateData);

    return {
      templateData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      submiting,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
  .submiting {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
